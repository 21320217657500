import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'

const routes = [
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login'),
    meta: {
      title: 'Login',
      tabbarShow: false,
    },
  },
  // 聊天室
  {
    name: 'chatPage',
    path: '/chatPage',
    component: () => import('./view/home/components/chatPage.vue'),
    meta: {
      title: 'chatPage',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 开始游戏
  {
    name: 'gameStart',
    path: '/gameStart',
    component: () => import('./view/home/components/gameStart.vue'),
    meta: {
      title: 'gameStart',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 开奖网
  {
    name: 'openPage',
    path: '/openPage',
    component: () => import('./view/openPage/index.vue'),
    meta: {
      title: 'OpenPage',
      tabbarShow: false,
      isLogin: false
    },
  },
  // 红包
  {
    name: 'hongbaoPage',
    path: '/hongbaoPage',
    component: () => import('./view/home/hongbaoPage.vue'),
    meta: {
      title: 'hongbaoPage',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 长龙助手
  {
    name: 'ChangLong',
    path: '/ChangLong',
    component: () => import('./view/game/ChangLong.vue'),
    meta: {
      title: 'ChangLong',
      tabbarShow: false,
      isLogin: true
    },
  },
  // DISAWAR MORNING
  {
    name: 'SattaKing',
    path: '/SattaKing',
    component: () => import('./view/game/SattaKing.vue'),
    meta: {
      title: 'Satta King',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 计划网
  {
    name: 'threeWin',
    path: '/threeWin',
    component: () => import('./view/game/threeWin.vue'),
    meta: {
      title: 'threeWin',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'notification',
    path: '/notification',
    component: () => import('./view/home/notification.vue'),
    meta: {
      title: 'Notification',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'messages',
    path: '/messages',
    component: () => import('./view/main/Messages.vue'),
    meta: {
      title: 'Messages',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'redeemGift',
    path: '/redeemGift',
    component: () => import('./view/main/RedeemGift.vue'),
    meta: {
      title: 'RedeemGift',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'recharge',
    path: '/recharge',
    component: () => import('./view/main/Recharge.vue'),
    meta: {
      title: 'Recharge',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 充值记录
  {
    name: 'rechargeHistory',
    path: '/rechargeHistory',
    component: () => import('./view/main/rechargeHistory.vue'),
    meta: {
      title: 'RechargeHistory',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 提现记录
  {
    name: 'WithdrawHistory',
    path: '/WithdrawHistory',
    component: () => import('./view/main/WithdrawHistory.vue'),
    meta: {
      title: 'WithdrawHistory',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'feedback',
    path: '/feedback',
    component: () => import('./view/main/Feedback.vue'),
    meta: {
      title: 'Feedback',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'aboutUs',
    path: '/aboutUs',
    component: () => import('./view/main/AboutUs/index.vue'),
    meta: {
      title: 'AboutUs',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'privacyPolicy',
    path: '/privacyPolicy',
    component: () => import('./view/main/AboutUs/privacyPolicy.vue'),
    meta: {
      title: 'PrivacyPolicy',
      tabbarShow: false,
      isLogin: false
    },
  },
  {
    name: 'riskDisclosureAgreement',
    path: '/riskDisclosureAgreement',
    component: () => import('./view/main/AboutUs/RiskDisclosureAgreement.vue'),
    meta: {
      title: 'RiskDisclosureAgreement',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 游戏统计
  {
    name: 'GameStats',
    path: '/GameStats',
    component: () => import('./view/main/GameStats.vue'),
    meta: {
      title: 'GameStats',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 新手指南
  {
    name: 'Guide',
    path: '/Guide',
    component: () => import('./view/main/Guide.vue'),
    meta: {
      title: 'Guide',
      tabbarShow: false,
      isLogin: true
    },
  },

  
  
  {
    name: 'withdraw',
    path: '/withdraw',
    component: () => import('./view/main/Withdraw.vue'),
    meta: {
      title: 'Withdraw',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'transAction',
    path: '/transAction',
    component: () => import('./view/main/TransAction.vue'),
    meta: {
      title: 'TransAction',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 洗码
  {
    name: 'rebate',
    path: '/rebate',
    component: () => import('./view/activity/Rebate.vue'),
    meta: {
      title: 'Rebate',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 每日签到
  {
    name: 'dailySignIn',
    path: '/dailySignIn',
    component: () => import('./view/activity/DailySignIn.vue'),
    meta: {
      title: 'DailySignIn',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 邀请奖金
  {
    name: 'invitationBonus',
    path: '/invitationBonus',
    component: () => import('./view/activity/InvitationBonus.vue'),
    meta: {
      title: 'InvitationBonus',
      tabbarShow: false,
      isLogin: true
    },
  },
  
  // 投注记录
  {
    name: 'betRecords',
    path: '/betRecords',
    component: () => import('./view/main/BetRecords.vue'),
    meta: {
      title: 'BetRecords',
      tabbarShow: false,
      isLogin: true
    },
  },
  // VIP
  {
    name: 'vipPage',
    path: '/vipPage',
    component: () => import('./view/main/vip.vue'),
    meta: {
      title: 'VipPage',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 大转盘
  {
    name: 'dzpPage',
    path: '/dzpPage',
    component: () => import('./view/home/dzpPage.vue'),
    meta: {
      title: 'dzpPage',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'settingCenter',
    path: '/settingCenter',
    component: () => import('./view/main/SettingCenter.vue'),
    meta: {
      title: 'SettingCenter',
      tabbarShow: false,
      isLogin: true
    },
  },
  
  {
    name: 'Home',
    path: '/',
    component: () => import('./view/home'),
    meta: {
      title: 'Home',
      tabbarShow: true,
      isLogin: false
    },
  },
  {
    name: 'gamePage',
    path: '/gamePage',
    component: () => import('./view/game/index.vue'),
    meta: {
      title: 'GamePage',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'customerService',
    path: '/customerService',
    component: () => import('./view/home/customerService.vue'),
    meta: {
      title: 'CustomerService',
      tabbarShow: false,
    },
  },
  {
    name: 'activity',
    path: '/activity',
    component: () => import('./view/activity/index.vue'),
    meta: {
      title: 'Activity',
      tabbarShow: true,
    },
  },
  {
    name: 'promotion',
    path: '/promotion',
    component: () => import('./view/promotion/index.vue'),
    meta: {
      title: 'Promotion',
      tabbarShow: true,
      isLogin: true
    },
  },
  // 邀请链接
  {
    name: 'PromotionShare',
    path: '/PromotionShare',
    component: () => import('./view/promotion/PromotionShare.vue'),
    meta: {
      title: 'PromotionShare',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 邀请=》下级数据
  {
    name: 'TeamReport',
    path: '/TeamReport',
    component: () => import('./view/promotion/TeamReport.vue'),
    meta: {
      title: 'TeamReport',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 邀请=》佣金明细
  {
    name: 'MyCommission',
    path: '/MyCommission',
    component: () => import('./view/promotion/MyCommission.vue'),
    meta: {
      title: 'MyCommission',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 邀请=》新增下级
  {
    name: 'Subordinate',
    path: '/Subordinate',
    component: () => import('./view/promotion/Subordinate.vue'),
    meta: {
      title: 'Subordinate',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 邀请=》邀请规则
  {
    name: 'PromotionRule',
    path: '/PromotionRule',
    component: () => import('./view/promotion/PromotionRule.vue'),
    meta: {
      title: 'PromotionRule',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 邀请=》佣金比例
  {
    name: 'RebateRatio',
    path: '/RebateRatio',
    component: () => import('./view/promotion/RebateRatio.vue'),
    meta: {
      title: 'RebateRatio',
      tabbarShow: false,
      isLogin: true
    },
  },
  {
    name: 'wallet',
    path: '/wallet',
    component: () => import('./view/wallet/index.vue'),
    meta: {
      title: 'Wallet',
      tabbarShow: true,
      isLogin: true
    },
  },
  {
    name: 'main',
    path: '/main',
    component: () => import('./view/main/index.vue'),
    meta: {
      title: 'Main',
      tabbarShow: true,
      isLogin: true
    },
  },
  {
    name: 'strongBox',
    path: '/strongBox',
    component: () => import('./view/main/StrongBox.vue'),
    meta: {
      title: 'StrongBox',
      tabbarShow: false,
      isLogin: true
    },
  },
  // 洗码率（VIP）
  {
    name: 'BettingRebateRatio',
    path: '/BettingRebateRatio',
    component: () => import('./view/main/BettingRebateRatio.vue'),
    meta: {
      title: 'BettingRebateRatio',
      tabbarShow: false,
      isLogin: true
    },
  },
];
// 路由模式 createWebHashHistory()
const router = createRouter({
  routes,
  history: createWebHistory(),
});
// 路由收尾
router.beforeEach((to, from, next) => {
  document.body.classList.remove('van-overflow-hidden');
  // 切换路由，重置数据
  store.state.loadShow = false;
  // 在每次路由切换前，判断最新版本号
  store.dispatch('isNewVersion');
  // 网页标题
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if(to.matched.some(res=>res.meta.isLogin)){
    if (sessionStorage['sessionId']) {
      next();
    }else{
      next({
        path:"/login",
        query:{
          redirect:to.fullPath
        }
      });
    }
  }else{
    next()
  }
});

export { router };
